import img1 from '../images/icon/wallet.png';
import img2 from '../images/icon/shopping-cart.png';
import img3 from '../images/icon/folder.png';
import img4 from '../images/icon/money-bag.png';

const dataPortfolio = [
    {
        img: img1,
        step:'Step 01',
        title : 'Head To Our Marketplace!',
        desc: 'Go to the link on the top right of the page "Take Action" to head to our Sustainable Action Marketplace!'
    },
    {
        img: img2,
        step:'Step 02',
        title : 'Connect Your Wallet',
        desc: 'Connect your wallet by clicking the button on the top right hand of the marketplace page!'
    },
    {
        img: img3,
        step:'Step 03',
        title : 'Buy Your NFT',
        desc: 'After connecting your wallet, you can purchase an NFT using the Mint button on the page!'
    },
    {
        img: img4,
        step:'Step 04',
        title : 'Show the world you made a difference!',
        desc: 'Showcase your Actionist NFT, proving you made a difference in the world and turn awareness into action!'
    },
]

export default dataPortfolio;