import icon1 from '../images/icon/database.png'
import icon2 from '../images/icon/lock.png'
import icon3 from '../images/icon/paymentt.png'
import icon4 from '../images/icon/timestaps.png'

const dataTechnology = [
    {
        icon: icon1,
        title: 'DUGUTOPIA - Worlds',
        desc: 'Vast sky box of interactive worlds that Brands, Influencers, Causes and We can build out to include games, interactive journeys, challenges and rich multi-media content to empower and drive sustainable action!'
    },
    {
        icon: icon2,
        title: 'DUGUTOPIA - Treasury',
        desc: 'Treasury of assets collected from revenues produced by ecosystem, which will be reinvested in on-chain liquidity provision protocols for yield which will be dsitributed into various functions of DUGUTOPIA, to support development, sustainable actions, community rewards payouts, marketing and more!'
    },
    {
        icon: icon3,
        title: 'Doing Good Reputation - Profile NFT',
        desc: 'Profile NFT can be upgraded by purchasing sustainable actions with DUGUTOKENs, the higher your reputation the more monthly rewards you receive from the treasury!'
    },
    {
        icon: icon4,
        title: 'Sustainable Action NFTs',
        desc: 'You can purchase these using DUGUTOKENs and you use them to build out your world, ex. planting a virtual tree NFT plants a real life tree!'
    },
]

export default dataTechnology;