import avt1 from '../images/testimonial/avt-02.png'
import avt2 from '../images/testimonial/avt-03.png'
import avt3 from '../images/testimonial/avt-04.png'
import avt4 from '../images/testimonial/avt-02.png'
import avt5 from '../images/testimonial/avt-03.png'
import avt6 from '../images/testimonial/avt-04.png'
import avt7 from '../images/testimonial/avt-02.png'
import avt8 from '../images/testimonial/avt-03.png'
import avt9 from '../images/testimonial/avt-04.png'
import avt10 from '../images/testimonial/avt-02.png'
import avt11 from '../images/testimonial/avt-03.png'
import avt12 from '../images/testimonial/avt-04.png'
import avt13 from '../images/testimonial/avt-02.png'

const dataTestimonials = [
    {
        text: '"Consumer spending is the most powerful force in World Change... If even a fraction of that spending can fight climate change, reduce poverty and inequality, the impact is enormous!"',
        avt: avt1,
        name: 'Stephanus Vermeulen,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Its always amazing to work with passionate individuals that want to make a change in the world and impact so it can be better!',
        avt: avt2,
        name: 'Jacob Magalhaes,',
        postion: 'Co-Founder & CBO'
    },
    {
        text: 'If we do not stop illegal wildlife hunting not only our generation will see a darker future but they will not be able to witness the beauty of our world!',
        avt: avt3,
        name: 'Gulli,',
        postion: 'Video Editing Team'
    },
    {
        text: 'I do not want to live in a world that is surrounded by trash, I want to be able to feel the nature!',
        avt: avt4,
        name: 'Gonzalo,',
        postion: 'Video Editing Team'
    },
    {
        text: 'Take care of our world so our future generation will be able to witness the beauty of our world',
        avt: avt5,
        name: 'Daniel,',
        postion: 'Video Editing Team'
    },
    {
        text: 'We are trying to raise awareness to the younger generation, we want to show them that this is not their problem, its their opportunity to help our planet!',
        avt: avt6,
        name: 'Franco Dubini,',
        postion: 'Chief Creative Officer'
    },
    {
        text: 'Dugut has made me look deeper into the world needs, lets make changes that our generation and the future one will appreciate!',
        avt: avt7,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt8,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt9,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt10,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt11,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },   {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt12,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt13,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
]

export default dataTestimonials;