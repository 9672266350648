const menus = [
    {
        id: 1,
        name: 'MISSION',
        links: '#',
        namesub: [
            
            {
                id: 1,
                sub: 'Our Mission',
                links: '/our-mission'
            },
            {
                id: 2,
                sub: 'Road Map',
                links: '/road-map'
            },
            {
                id: 3,
                 sub: 'Team',
                links: '/team'
            },
          
            {
                id: 4,
                sub: 'FAQ',
                links: '/faq'
            },
        ]
    },
    {
        id: 2,
        name: 'ACTIONISTS',
        links: '/about'
    },{
        id: 3,
        name: 'DUGUTOPIA',
        links: 'https://www.dugutopia.com'
    },
    {
        id: 4,
        name: 'EXPLORE',
        links: '#',
        namesub: [

            {
                id: 1,
                sub: 'WE DO GOOD',
                links: ('www.wedugut.com')
            },
            {
                id: 2,
                sub: 'DuGut App',
                links: 'www.dugut.app'
            },
            {
                id: 3,
                sub: 'DUGUTOPIA',
                links: 'www.dugutopia.com'
            },
            {
                id: 4,
                sub: 'Company',
                links: 'www.dugut.app/company'
            },
        ],
    },
    {
        id: 5,
        name: 'CONTACT',
        links: '/contact'
    },
    
]

export default menus;