const dataFaq = [
    {
        title: 'What stage is DUGUTOPIA in?',
        text: 'We currently have our first world live at dugutopia.com, as well as our MVP app live at www.dugut.app! However, we are in a fundraising phase to accumulate treasury funds to support development and marketing! Come join the movement!',
        show: 'true'
    },
    {
        title: 'What is the best way to help the DUGUT movement?',
        text: 'Purchasing one of these Actionist NFTs helps support our development, raise funds for the treasury AND generate real world sustainable actions, this is the best way to help while we are still in early beta!',

    },
    {
        title: 'Why is there no token sale yet?',
        text: 'The current landscape of Crypto is leading to most token launches being drained of liquidity by eary investors and as a result, being a company building with a long term vision, we will avoid any hasty token launches that can not accumulate massive amounts of liquidity for the ecosystem.',
    },
    {
        title: 'Who is behind all of this, do you have the capabilities to pull it off?',
        text: 'This idea was founded by a adveritsing industry veteran, take a look at our distinguised team or head to wedugut.com to see the mission and goal for the DUGUT momvement!',
    },
]

export default dataFaq;