import { React , useState } from 'react';
import { Link } from 'react-router-dom'

import item from '../../assets/fake-data/item';

const About = () => {

    const [data] = useState(
        {
            subtitle: 'About Us',
            title: 'Hight Quality NFT  Collections',
            desc1: 'The Actionist Club is an early-adopter pass for the DUGUTOPIA metaverse! Each represents your Each Actionist NFT holder will have proof of their dedication to making an impact in the world!',
            desc2: 'The goal is to offer its users/players a game-like experience which is fun, and engaging that stimulates people to learn why and how to adopt a more sustainable lifestyle, as well as creating Real-Life Social and Environmental Impact. So what are you waiting for? Join this exclusive community and show off your commitment to doing good and being the change, because it is YOUR Future!',
        }
    )

    return (
        <section className="tf-section section-about">
            <div className="container">
                <div className="row reverse">
                    <div className="col-xl-7 col-md-12">
                        <div className="group-image">
                            <div className="left">
                                <div className="item bg-1"><img src={item.item8} alt="ACTIONIST" /></div>
                            </div>
                            <div className="right">
                                <div className="item bg-2"><img src={item.item9} alt="ACTIONIST" /></div>
                                <div className="item bg-3"><img src={item.item10} alt="ACTIONIST" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-12">
                        <div className="block-text pt-12">
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">{data.subtitle}</h5>
                            <h3 className="title mb-58" data-aos="fade-up" data-aos-duration="1000">{data.title}</h3>
                            <p className="fs-21 mb-33" data-aos="fade-up" data-aos-duration="1000">{data.desc1}</p>
                            <p className="fs-18 line-h17 mb-41" data-aos="fade-up" data-aos-duration="1000">{data.desc2}</p>
                            <Link to="/about" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">More About Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;