import icon1 from '../images/icon/database.png'
import icon2 from '../images/icon/lock.png'
import icon3 from '../images/icon/paymentt.png'
import icon4 from '../images/icon/timestaps.png'

const dataImpact = [
    {
        icon: icon1,
        title: 'Planting trees!',
        desc: 'Planting Mangrove trees to sequester carbon from our atmosphere and keep our skies clean!'
    },
    {
        icon: icon2,
        title: 'Providing Access to Clean Water',
        desc: 'Funds will go towards building Bio-Sand filters providing thousands in Kenya with reliable and clean water, saving lives!'
    },
    {
        icon: icon3,
        title: 'Protecting Endangered Animals and Our Oceans',
        desc: 'Funds will go to protecting Endangered Animals in Kenya and Cleaning up Plastic waste from beaches!'
    },
    {
        icon: icon4,
        title: 'Providing Access to Sustainable Food sources',
        desc: 'Providing access to food for those in abject poverty and who are malnurished!'
    },
]

export default dataImpact;