import img1 from '../images/partners/logo-01.png'
import img2 from '../images/partners/logo-02.png'
import img3 from '../images/partners/logo-03.png'
import img4 from '../images/partners/logo-04.png'
const dataPartners = [
    {
        img: img1,
        border: 'border-r border-b'
    },
    {
        img: img2,
        border: 'border-r border-b'
    },
    {
        img: img3,
        border: 'border-r border-b'
    },
    {
        img: img4,
        border: 'border-b'
    },
  
]

export default dataPartners;