import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataCard from '../assets/fake-data/data-card';
import dataFaq from '../assets/fake-data/data-faq';
import dataMontono from '../assets/fake-data/data-montono';
import dataPartners from '../assets/fake-data/data-partners';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataTeam from '../assets/fake-data/data-team';
import dataTestimonials from '../assets/fake-data/data-testimonials';
import dataTechnology from '../assets/fake-data/data-technology';
import About from '../components/layouts/About';
import Action from '../components/layouts/Action';
import FAQ from '../components/layouts/FAQ';
import Montono from '../components/layouts/Montono';
import Partners from '../components/layouts/Partners';
import Portfolio from '../components/layouts/Portfolio';
import RoadMap from '../components/layouts/RoadMap';
import Speciality from '../components/layouts/Speciality';
import Team from '../components/layouts/Team';
import Technology from '../components/layouts/Technology';
import Testimonial from '../components/layouts/Testimonial';
import SliderOne from '../components/slider/SliderOne';
import Speciality2 from '../components/layouts/Speciality2';
import dataValue from '../assets/fake-data/data-value';
import Value from '../components/layouts/Value';

const Home01 = () => {
    return (
        <div>
            <Header />
            <SliderOne />
            <About />
            <Value data={dataValue} />
            <Technology data={dataTechnology} />
            <Portfolio data={dataPortfolio} />
            <Montono data={dataMontono} />
            <RoadMap data={dataRoadMap} />
        
            <Team data={dataTeam} />
            <Partners data={dataPartners} />
            <Testimonial data={dataTestimonials} />
            <FAQ data={dataFaq} />
            <Action />
            <Footer />
        </div>
    );
}

export default Home01;