import img1 from '../images/items/01.png'
import img2 from '../images/items/02.png'
import img3 from '../images/items/03.png'
import img4 from '../images/items/04.png'
import img5 from '../images/items/05.png'
import img6 from '../images/items/06.png'
import img7 from '../images/items/07.png'
import img8 from '../images/items/08.png'
import img9 from '../images/items/09.png'
import img10 from '../images/items/10.png'
import img11 from '../images/items/11.png'
import img12 from '../images/items/12.png'
import img13 from '../images/items/13.png'
import img14 from '../images/items/14.png'

const dataMontono = [
    {
        img: img1,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img2,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img3,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img4,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img5,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img6,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img7,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img8,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img9,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img10,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img11,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img12,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img13,
        title: 'Actionist NFT Series Example'
    },
    {
        img: img14,
        title: 'Actionist NFT Series Example'
    },
]

export default dataMontono;