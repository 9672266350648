import img1 from '../images/team/team-01.jpg'
import img2 from '../images/team/team-02.jpg'
import img3 from '../images/team/team-03.jpg'
import img4 from '../images/team/team-04.jpg'
import img5 from '../images/team/team-05.jpg'
import img6 from '../images/team/team-06.jpg'
import img7 from '../images/team/team-07.jpg'
import img8 from '../images/team/team-08.jpg'
import img9 from '../images/team/team-09.jpg'
import img10 from '../images/team/team-10.jpg'
import img11 from '../images/team/team-11.jpg'
import img12 from '../images/team/team-12.jpg'
import img13 from '../images/team/team-13.jpg'
import img14 from '../images/team/team-14.jpg'
import img15 from '../images/team/team-15.jpg'
import img16 from '../images/team/team-16.jpg'
import img17 from '../images/team/team-17.jpg'
import img18 from '../images/team/team-18.jpg'
import img19 from '../images/team/team-19.jpg'
import img20 from '../images/team/team-20.jpg'

const dataTeam = [
    {
        img: img1,
        name:'STEPHANUS VERMEULEN',
        position: 'FOUNDER & CEO'
    },
    {
        img: img2,
        name:'JACOB MAGALHAES',
        position: 'COFOUNDER & CBO'
    },
    {
        img: img3,
        name:'FRANCO DUBINI',
        position: 'Jr. PARTNER/ CREATIVE DIRECTOR'
    },
    {
        img: img4,
        name:'MARCELO',
        position: 'SALES'
    },
    {
        img: img5,
        name:'GIL SOARES',
        position: 'GRAPHIC DESIGN LEAD'
    },
    {
        img: img6,
        name:'JORGE MAGALHAES',
        position: 'Sr. PARTNER/ BUSINESS STRATEGY SUSTAINABILITY'
    },
    {
        img: img7,
        name:'MARK GROSFELD',
        position: 'BOARD EXECUTIVE, ADVISOR'
    },
    {
        img: img8,
        name:'JIMI JAMES COHEN',
        position: 'ADVISOR/IMPACT COORDINATOR'
    },
    {
        img: img9,
        name:'THIERRY PEEK',
        position: 'ADVISOR'
    },
    {
        img: img10,
        name:'EMIEL STAPAERTS',
        position: 'ADVISOR'
    },
    {
        img: img11,
        name:'ERIC KIP',
        position: 'BRAND ADVISOR'
    },
    {
        img: img12,
        name:'DARREN CHUCKRY',
        position: 'PARTNER\ BUSINESS STRATEGY'
    },
    {
        img: img13,
        name:'SEAN ANGUS',
        position: 'ADVERTISING & PR ADVISOR'
    },
    {
        img: img14,
        name:'BULENT CANDAN',
        position: 'BRAND LOYALTY ADVISOR'
    },
    {
        img: img15,
        name:'CHRIS H. LEEB',
        position: 'ADVISOR'
    },
    {
        img: img16,
        name:'COREY HARNISH',
        position: 'ADVISOR'
    },
    {
        img: img17,
        name:'MICHAEL GESZEL',
        position: 'ADVISOR'
    },
    {
        img: img18,
        name:'MARIA GABRIELA MAYZ',
        position: 'SOUND DESIGN'
    },
    {
        img: img19,
        name:'SCARLOS DIAS',
        position: 'ADMINISTRATION'
    },
    {
        img: img20,
        name:'CESAR COUTO FERREIRA',
        position: 'ADVISOR'
    }
]

export default dataTeam;