const dataRoadMap = [
    {
        time: '2022 - Q1',
        title: 'Completed',
        desc: 'DUGUT APP Beta and DUGUTOPIA Promo World successfully Launched ',
        class: 'left mt--23'
    },
    {
        time: '2023 - Q2',
        title: 'Coming Up',
        desc: 'Launch of ACTIONIST Series Whitelist and Partnership Competitions     ',
        class: 'right mt-223'
    },
    {
        time: '2023 - Q3',
        title: 'Later this year',
        desc: ' Launch of Sustainable Action NFT Marketplace with Direct Action NFTs available to Purchase as well as NFT Public sale, Reveal and Impact Campaign updates ',
        class: 'left mt--23'
    },
    {
        time: '2023 - Q4',
        title: 'Launch of Treasury Fund',
        desc: 'By end of year, the Treasury fund will be launched with VC funds, NFT sales and Revenue from DUGUT LDA',
        class: 'right mt-200'
    },
   
    {
        time: '2024 - Q1/2',
        title: 'Network Token and Protocol Launch',
        desc: 'ICO for Network token, and Launch of Sustainable Brand Action Smart Contract Protocol',
        class: 'left mt-23'
    },
    {
        time: '2024 - Q3/4',
        title: 'DUGUTOPIA Infinity Launch ',
        desc: 'Launch of Infinite world ANYONE can build in with Sustainable Action NFTs, this is a non decentralized video game stage for experiences to be developed by influencers, brands and causes!',
        class: 'right mt--200'
    },
    {
        time: '2025 - Q1',
        title: 'DUGUTOPIA Decentralized Land sale',
        desc: 'Actionist NFT holders can claim their plot of land in DUGUTOPIA, the rest of the supply (90%) will be on sale at $1000',
        class: 'left mt-23'
    },
    {
        time: '2025 - Q2',
        title: 'Decentralized Metaverse Launch and User Scaling',
        desc: 'Launch of the Decentralized Metaverse, ',
        class: 'right mt-200'
    },
    {
        time: '2025 - Q3/4',
        title: 'Social Reputation Identity NFT and App launch',
        desc: 'Earn monthly dividends from treasury fund as you begin upgrading your Identity NFT by taking actions to create a better world, User generated data will be aggregated and subjectively reviewed via a mobile app, launched in the metaverse and on app stores.',
        class: 'left mt-23'
    }
]

export default dataRoadMap;