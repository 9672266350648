import img1 from '../images/testimonial/testimonial-01.jpg'
import avt1 from '../images/testimonial/avt-01.png'
import avt2 from '../images/testimonial/avt-02.png'
import avt3 from '../images/testimonial/avt-03.png'
import avt4 from '../images/testimonial/avt-04.png'
import avt5 from '../images/testimonial/avt-05.png'
import avt6 from '../images/testimonial/avt-06.png'
import avt7 from '../images/testimonial/avt-07.png'
import avt8 from '../images/testimonial/avt-08.png'
import avt9 from '../images/testimonial/avt-09.png'
import avt10 from '../images/testimonial/avt-10.png'
import avt11 from '../images/testimonial/avt-11.png'
import avt12 from '../images/testimonial/avt-12.png'
import avt13 from '../images/testimonial/avt-13.png'
import img2 from '../images/testimonial/testimonial-02.jpg'
import img3 from '../images/testimonial/testimonial-03.jpg'
import img4 from '../images/testimonial/testimonial-04.jpg'
import img5 from '../images/testimonial/testimonial-05.jpg'
import img6 from '../images/testimonial/testimonial-06.jpg'
import img7 from '../images/testimonial/testimonial-07.jpg'
import img8 from '../images/testimonial/testimonial-08.jpg'
import img9 from '../images/testimonial/testimonial-09.jpg'
import img10 from '../images/testimonial/testimonial-10.jpg'
import img11 from '../images/testimonial/testimonial-11.jpg'
import img12 from '../images/testimonial/testimonial-12.jpg'
import img13 from '../images/testimonial/testimonial-13.jpg'

const dataTestimonials = [
    {
        img: img1,
        text: '"Consumer spending is the most powerful force in World Change... If even a fraction of that spending can fight climate change, reduce poverty and inequality, the impact is enormous!"',
        avt: avt1,
        name: 'Stephanus Vermeulen,',
        postion: 'Founder & CEO'
    },
 
    {
        img: img2,
        text: 'Its always amazing to work with passionate individuals that want to make a change in the world and impact so it can be better!',
        avt: avt2,
        name: 'Jacob Magalhaes,',
        postion: 'Co-Founder & CBO'
    },
 
    {
        img: img3,
        text: 'If we do not stop illegal wildlife hunting not only our generation will see a darker future but they will not be able to witness the beauty of our world!',
        avt: avt3,
        name: 'Gulli,',
        postion: 'Video Editing Team'
    },
 
    {
        img: img4,
        text: 'I do not want to live in a world that is surrounded by trash, I want to be able to feel the nature!',
        avt: avt4,
        name: 'Gonzalo,',
        postion: 'Video Editing Team'
    },
 

    {
        img: img5,
        text: 'Take care of our world so our future generation will be able to witness the beauty of our world',
        avt: avt5,
        name: 'Daniel,',
        postion: 'Video Editing Team'
    },
 
    {
        img: img6,
        text: 'We are trying to raise awareness to the younger generation, we want to show them that this is not their problem, its their opportunity to help our planet!',
        avt: avt6,
        name: 'Franco Dubini,',
        postion: 'Chief Creative Officer'
    },
 
    {
        img: img7,
        text: 'Dugut has made me look deeper into the world needs, lets make changes that our generation and the future one will appreciate!',
        avt: avt7,
        name: 'Paulo',
        postion: 'Development Team'
    },
 
    {
        img: img8,
        text: 'Compared to the normal content we see in social media, in DuGut we try to create and teach a more impactful truth and awareness to the world!',
        avt: avt8,
        name: 'Pedro,',
        postion: 'Graphics Team'
    },
 
    {
        img: img9,
        text: 'I try to design things more appealing to people, so I can show them through my design the message of our world!',
        avt: avt9,
        name: 'Joao,',
        postion: 'Graphics team'
    },
    {
        img: img10,
        text: 'What we are creating in Dugut is important to our world, if we manage to work together with other people we can save the planet!',
        avt: avt10,
        name: 'Rafael,',
        postion: 'Development Team'
    },
 
    {
        img: img11,
        text: 'We create musical content about a variation of problems such as bullying, pollution, equality and many more, so we can create a better world!',
        avt: avt11,
        name: 'Maria Gabriela,',
        postion: 'Musician'
    }, 
 
    {
        img: img12,
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
       avt: avt12,
       name: 'Gabriela,',
       postion: 'Social Media'
   },

    {
        img: img13,
        text: 'No matter how small Dugut is, our works and ideas are meant to change the world for a better place',
        avt: avt13,
        name: 'Luiseiney,',
        postion: 'Admin'
    },
 
]

export default dataTestimonials;