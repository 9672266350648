import React from 'react';
import CountUp from 'react-countup';

import icon from '../../assets/fake-data/icon';

const CounterTwo = () => {
    return (
        <section className="tf-section section-counter" id='counter'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="counter__body" data-aos="fade-down" data-aos-duration="1000">
                            <div className="counter">
                                <img src={icon.users} alt="Monteno" />
                                <div className="number-counter">
                                    <CountUp end={100} /> Trees
                                </div>
                                <h5 className="title">Planted per NFT</h5>    
                            </div>
                            <div className="counter">
                                <img src={icon.assets} alt="Monteno" />
                                <div className="number-counter">
                                <CountUp end={10} /> People
                                </div>
                                <h5 className="title">Given Access to Water per NFT</h5>    
                            </div>
                            <div className="counter">
                                <img src={icon.trading} alt="Monteno" />
                                <div className="number-counter">
                                <CountUp end={100} />Meters
                                </div>
                                <h5 className="title">of beach plastic removed <b>per NFT</b> </h5>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterTwo;