import React from 'react';
import { Link } from 'react-router-dom'

import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataImpact from '../assets/fake-data/data-Impact';
import Impact from '../components/layouts/Impact';
import mission from '../assets/images/pages/our-mission.png'
import vission from '../assets/images/pages/our-vission.png'
import Action from '../components/layouts/Action';
import RoadMap from '../components/layouts/RoadMap';
import img from '../assets/images/items/group-ntf-03.png'
import dataTechnology from '../assets/fake-data/data-technology';
import Technology from '../components/layouts/Technology';
import Counter from '../components/layouts/Counter';
import CounterTwo from '../components/layouts/CounterTwo';

const OurMission = () => {
    return (
        <div className='mission'>
            <Header />
            <section className="tf-section page-title">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body rm">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">Visions & Mission</h2>
                                <p className="fs-24 mb-33" >Read through our vision below and how we  <br /> we will accomplish our mission to create a <br /> doing good digital economy!</p>
                            </div>
                            <img className="s2" src={img} alt="Monteno" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section visions">
                <div className="container">
                    <div className="row reverse">
                        <div className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                                <img src={mission} alt="Monteno" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Our Vision</h5>
                                <h3 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">Build an Economic Engine around Doing Good in the Metaverse </h3>
                                <p className="fs-18 mb-41" data-aos="fade-up" data-aos-duration="1000">DUGUTOPIA is a collaborative and impact driven metaverse ecosystem. When you take action in the digital world, sustainable action will occur in the real world, sponsored by brands! <b> The goal is to offer its users/players a game-like experience which is fun, and engaging that stimulates people to learn why and how to adopt a more sustainable lifestyle, as well as creating Real-Life Social and Environmental Impact. Click the link below to join the beta! </b> </p>
                                <Link to="#" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section missions">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Our Mission</h5>
                                <h3 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">Real Utility and Value for each Investor</h3>
                                <p className="fs-18 mb-29" data-aos="fade-up" data-aos-duration="1000">
                                    Generate impact, invest in the future of doing good, and show the world you made an impact, all with 1 NFT! </p>
                                <ul data-aos="fade-up" data-aos-duration="1000">
                                    <li><i className="fa fa-check"></i><p className="fs-18">Receive a piece of land in DUGUTOPIA, valued at $1000! </p></li>
                                    <li><i className="fa fa-check"></i><p className="fs-18">Receive an upgraded charachter NFT with higher revenue from the Treasury! </p></li>
                                    <li><i className="fa fa-check"></i><p className="fs-18">Receive whitelist for our ICO token launch, as well as special access to our Beta! </p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-right"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                                <img src={vission} alt="Monteno" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Impact data={dataImpact}/>
            <CounterTwo />
            <Technology data={dataTechnology} />
            <Counter />
            <RoadMap data={dataRoadMap} />

            <Action />
            <Footer />
        </div>
    );
}

export default OurMission;