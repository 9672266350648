import img1 from '../images/icon/wallet.png';
import img2 from '../images/icon/shopping-cart.png';
import img3 from '../images/icon/folder.png';
import img4 from '../images/icon/money-bag.png';

const dataValue = [
    {
        img: img1,
        step:'OWN',
        title:'DECENTRALIZED LAND OWNERSHIP',
        desc: 'Actionist NFT holders can claim a plot of land in the decentralized area of DUGUTOPIA, the value of which will be 2-4x higher than the NFTs public price!',
    },
    {
        img: img2,
        step:'INVEST',
        title:'WHITELIST TO INVEST IN OUR ICO',
        desc: 'We are releasing a blockchain solution aimed at incentivizing the youth to generate impact and turn activism into ACTION, NFT owners will be able to invest in the ICO of our network token!',
    },
    {
        img: img3,
        step:'EARN',
        title:'A SUSTAINABLE INCOME',
        desc: 'DUGUTOPIAS Economy will have a yield treasury which accumulates blockchain cash flow producing assets, as a NFT owner you will receive a dividend payout for supporting DUGUTOPIA.',
    },
    {
        img: img4,
        step:'IMPACT',
        title:'REAL LIFE SUSTAINABLE ACTIONS',
        desc: 'We generate real life impact in ALL our solutions, so we will be planting trees, providing access to clean water, aiding poverty, cleaning up plastic waste, and protecting endangered animals!',
    },
]

export default dataValue;